export enum FeatureFlag {
  TranslateMessage = 'translate-message',
  MergeTaskViews = 'merge-task-views',
  TestFlag = 'test_flag',
  ChatGQLQuery = 'chat-gql-query',
  ProgramStatusDateSelection = 'program-status-date-selection',
  PathwayReviewOwners = 'pathway-review-owners',
  Fuh = 'fuh',
  ConsentUpload = 'consent_upload',
  DeescalateCarePathway = 'deescalate_care_pathway',
  BulkTaskingAssignOthers = 'bulk_tasking_assign_others',
  EngagementPodReassignment = 'engagement_pod_reassignment',
  DisasterPreparednessSurvey = 'Disaster_Preparedness_Survey',
  TaskDueDateFilter = 'tasks_due_date_filter',
}
