import {
  ActionIcon,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  Group,
  Radio,
  Select,
  TextInput,
  Title,
  Text,
  Alert,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { useEffect } from 'react';
import { IconAlertTriangle, IconPlus, IconTrash } from '@tabler/icons-react';
import { CodingInput } from '@medplum/react';
import { HasContactFields, PhoneNumber } from '@/components/shared/ContactReviewForm';
import { ContactType, HL7ValueSet, PhoneStatus, PreferredLanguage, extractValues } from 'const-utils';
import { UnitedStates } from '@/utils/usa';
import { MaybeExistingContactForm } from './MaybeExistingContactForm/MaybeExistingContactForm';
import { PhoneInput } from '../design-system/PhoneInput';
import { useMaybeExistingContacts } from './MaybeExistingContactForm/MaybeExistingContactContext';

interface AbstractContactFormProps<T extends HasContactFields> {
  formWithContact: UseFormReturnType<T>;
  maybeNewContactIntended?: boolean;
  openAddContactModal?: () => void;
}

const ContactTypeCodes = extractValues(ContactType);
const PreferredLanguageCodes = extractValues(PreferredLanguage);

export default function ContactEditForm<T extends HasContactFields>({
  formWithContact,
  maybeNewContactIntended = false,
  openAddContactModal,
}: AbstractContactFormProps<T>): JSX.Element {
  const form = formWithContact as unknown as UseFormReturnType<HasContactFields>;
  const [phoneStatusCodes] = PhoneStatus.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
  const { setContact } = useMaybeExistingContacts();
  useEffect(() => {
    setContact(form.values.contact);
  }, [form.values.contact, setContact]);

  return (
    <>
      <Group grow>
        <TextInput withAsterisk label="Contact's first name" {...form.getInputProps('contact.firstName')} />
        <TextInput withAsterisk label="Contact's last name" {...form.getInputProps('contact.lastName')} />
      </Group>
      {maybeNewContactIntended && (
        <Alert color="orange" icon={<IconAlertTriangle />} mt="sm">
          <Text>
            If you are intending to create a{' '}
            <Text span fw="bold">
              new
            </Text>{' '}
            contact, use the{' '}
            <Text
              span
              fw="bold"
              td="underline"
              style={{ cursor: openAddContactModal ? 'pointer' : undefined }}
              onClick={() => openAddContactModal?.()}
            >
              Add Contact
            </Text>{' '}
            workflow instead to avoid overwriting the existing contact.
          </Text>
          <Text mt="md">
            If the{' '}
            <Text span fw="bold">
              existing
            </Text>{' '}
            contacts name has changed, you can continue to use this form.
          </Text>
          <Text mt="md" c="dimmed">
            Note: If the patient is transferring to a new caregiver, you should create a new contact and delete the old.
          </Text>
        </Alert>
      )}
      <br />
      <Group grow>
        <Select
          label="Contact type"
          placeholder="Please select"
          data={ContactTypeCodes.map((d) => d.display) as string[]}
          {...form.getInputProps('contact.contactType')}
        />
        <label>
          <Text>Relationship to patient</Text>
          <CodingInput
            name="contact.relationship"
            path="contact.relationship"
            required
            binding={HL7ValueSet.RelatedPersonRelationshipType}
            defaultValue={form.values.contact.relationship}
            {...form.getInputProps('contact.relationship')}
            withHelpText={false}
          />
        </label>
      </Group>

      <br />
      <span>Primary contact</span>
      <Checkbox
        label="This is the patient's primary contact"
        pt="md"
        {...form.getInputProps('contact.primary', { type: 'checkbox' })}
      />

      <br />
      <Title order={5}>Contact's language preference</Title>
      <br />
      <Checkbox
        {...form.getInputProps('contact.usePatientLanguage', { type: 'checkbox' })}
        label="Same as patient's language preference"
      />
      <Collapse in={!form.values.contact.usePatientLanguage}>
        <br />
        <Group>
          <Select
            withAsterisk
            label="Language"
            placeholder="Select language"
            data={PreferredLanguageCodes.map((d) => d.display) as string[]}
            {...form.getInputProps('contact.language')}
          />
          <Checkbox
            label="Requires interpreter"
            pt="lg"
            {...form.getInputProps('contact.requiresInterpreter', { type: 'checkbox' })}
          />
        </Group>
      </Collapse>
      <br />
      <Title order={5}>Contact Information</Title>
      <br />
      {form.values.contact.phoneNumbers?.map((phone, index) => (
        <React.Fragment key={`${phone.type}-${phone.status}-${index}`}>
          {index > 0 && (
            <>
              <br />
              <Divider />
              <br />
            </>
          )}
          <Group justify="space-between">
            <PhoneInput style={{ flex: 1 }} required {...form.getInputProps(`contact.phoneNumbers.${index}.number`)} />
            <Select
              style={{ flex: 1 }}
              label="Phone type"
              placeholder="Select phone type"
              data={['home', 'work', 'mobile']}
              {...form.getInputProps(`contact.phoneNumbers.${index}.type`)}
            />
            <Select
              style={{ flex: 1 }}
              label="Phone status"
              placeholder="Please select"
              data={phoneStatusCodes as string[]}
              {...form.getInputProps(`contact.phoneNumbers.${index}.status`)}
            />
            <Radio
              label="Primary"
              pt="lg"
              {...form.getInputProps(`contact.phoneNumbers.${index}.primary`, { type: 'checkbox' })}
              onChange={() => {
                const newPhoneNumbers = form.values.contact.phoneNumbers?.map((phone, i) => {
                  if (i === index) {
                    return { ...phone, primary: true };
                  }
                  return { ...phone, primary: false };
                });
                form.setFieldValue('contact.phoneNumbers', newPhoneNumbers);
              }}
            />
            {form.values.contact.phoneNumbers?.length > 1 && (
              <ActionIcon
                mt="lg"
                onClick={() => {
                  form.setFieldValue(
                    'contact.phoneNumbers',
                    form.values.contact.phoneNumbers?.filter((_, i) => i !== index),
                  );
                }}
              >
                <IconTrash size={22} color="red" />
              </ActionIcon>
            )}
          </Group>
        </React.Fragment>
      ))}
      <br />
      <Button
        type="button"
        onClick={() => {
          const existingNumbers = [...(form.values.contact.phoneNumbers || [])];
          const newNumber: PhoneNumber = {
            number: '',
            primary: false,
          };
          if (existingNumbers.length === 0) {
            newNumber.primary = true;
          }
          form.setFieldValue('contact.phoneNumbers', [...existingNumbers, newNumber]);
        }}
        variant="outline"
        leftSection={<IconPlus size={16} />}
        radius="md"
      >
        Add another phone number
      </Button>
      <br />
      <br />
      <TextInput type="email" label="Email" w="300px" placeholder="" {...form.getInputProps('contact.emailAddress')} />
      <br />
      <Title order={5}>Contact's address</Title>
      <br />
      <Checkbox
        {...form.getInputProps('contact.usePatientAddress', { type: 'checkbox' })}
        label="Patient lives with contact"
      />
      <Collapse in={!form.values.contact.usePatientAddress}>
        <br />
        <Group grow align="start">
          <TextInput
            withAsterisk
            label="Address line 1"
            placeholder="123 E Main Street"
            {...form.getInputProps('contact.address.line1')}
          />
          <TextInput label="Address line 2" placeholder="Unit 1" {...form.getInputProps('contact.address.line2')} />
        </Group>
        <br />
        <Grid grow>
          <Grid.Col span={6}>
            <TextInput
              withAsterisk
              label="City"
              placeholder="Chicago"
              {...form.getInputProps('contact.address.city')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              withAsterisk
              label="State"
              placeholder="Select state"
              data={UnitedStates.map((state) => ({ label: state.name, value: state.abbreviation }))}
              {...form.getInputProps('contact.address.state')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              withAsterisk
              label="Zip code"
              placeholder="60601"
              {...form.getInputProps('contact.address.zip')}
            />
          </Grid.Col>
        </Grid>
      </Collapse>
      <MaybeExistingContactForm />
    </>
  );
}
