import { Button, Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { SearchPatientQueryVariables } from 'medplum-gql';
import { isEmpty, omitBy } from 'lodash';
import React from 'react';
import { PhoneInput } from '../../design-system/PhoneInput';

interface FormProps {
  reset: () => void;
  submit: (formData: SearchPatientQueryVariables) => void;
  initialValues: Partial<SearchPatientQueryVariables>;
}

export function SearchForm({ reset, submit, initialValues }: FormProps): JSX.Element {
  const form = useForm({
    initialValues: {
      birthdate: '',
      name: '',
      phone: '',
      ...omitBy(initialValues, isEmpty),
    },
  });

  const resetForm = (): void => {
    form.reset();
    reset();
  };

  const onSubmit = (formData: SearchPatientQueryVariables): void => {
    submit(formData);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="row" gap="md" align="end">
        <TextInput name="name" placeholder="Jane Doe" label="Name" {...form.getInputProps('name')} />
        <TextInput
          name="birthdate"
          label="Date of Birth"
          placeholder="MM/DD/YYYY"
          {...form.getInputProps('birthdate')}
        />
        <PhoneInput label="Phone Number" name="phone" value={form.values.phone} {...form.getInputProps('phone')} />
        <Button name="submit" radioGroup="md" color="imagine-green" type="submit" mb="4px" size="compact-md">
          Search
        </Button>
        <Button onClick={resetForm} radius="md" color="imagine-green" variant="outline" mb="4px" size="compact-md">
          Clear
        </Button>
      </Flex>
    </form>
  );
}
