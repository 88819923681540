import React, { useEffect, useState } from 'react';
import { Button, Group, Modal, Select, Text } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { CareTeamType } from 'const-utils';
import { CareTeam } from '@medplum/fhirtypes';
import { notifications } from '@mantine/notifications';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';
import { handlePodChange } from 'imagine-dsl/services/careTeamService';

interface EngagementPodAssignmentModalProps {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  engagementCareTeam: CareTeam;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

const EngagementPodAssignmentModal = ({
  isModalOpen,
  setModalOpen,
  engagementCareTeam,
  refetch,
}: EngagementPodAssignmentModalProps) => {
  const medplum = useMedplum();

  const [engagementPods, setEngagementPods] = useState<CareTeam[]>([]);
  const [updatedPodId, setUpdatedPodId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const pods = await medplum.search('CareTeam', {
          _tag: CareTeamType.EngagementPod,
        });
        setEngagementPods(pods.entry?.map((entry) => entry.resource) as CareTeam[]);
      } catch (error) {
        notifications.show({
          title: 'Error fetching engagement pods:',
          message: error as string,
          color: 'status-error',
        });
      }
    })().catch((error) => {
      notifications.show({
        title: 'Error fetching engagement pods:',
        message: error as string,
        color: 'status-error',
      });
    });
  }, [medplum]);

  const onSubmitHandler = async () => {
    setLoading(true);
    try {
      await handlePodChange(medplum, engagementCareTeam, updatedPodId, engagementPods);
      notifications.show({
        title: 'Success',
        message: 'Care team updated successfully.',
        color: 'status-success',
      });
      await refetch();
      setModalOpen(false);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error as string,
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Text size="xl" fw={700} c="imagine-green">
          Update engagement pod assignment
        </Text>
      }
      opened={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      radius="lg"
      centered
    >
      <Text fz="xs" mb="sm">
        <Select
          data={engagementPods.map((pod) => ({
            value: pod.id ?? '',
            label: pod.name ?? '',
          }))}
          value={updatedPodId}
          onChange={(value) => setUpdatedPodId(value ?? '')}
        />
      </Text>
      <Group mt="md" justify="flex-end">
        <Button variant="outline" onClick={() => setModalOpen(false)} radius="md">
          Cancel
        </Button>
        <Button type="submit" radius="md" disabled={!updatedPodId} loading={loading} onClick={onSubmitHandler}>
          Save changes
        </Button>
      </Group>
    </Modal>
  );
};

export default EngagementPodAssignmentModal;
